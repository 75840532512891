/*================================================================
 *                Big Badge Image
 *================================================================*/
import React from 'react'

import styles from './bigBadgeImage.module.scss'
import iconDelete from './assets/close-icon.svg'
import plusIcon from './assets/plus-icon.svg'


export interface BigBadgeImageProps {
    imgSrc?: string;
    title?: string;
    titleRight?: string;
    subTitle?: string;

    onDelete?: () => void; 
    onClick ?: () => void;
    plusIconRight?: boolean;
    validatedIconRight?: boolean;
    message?: string;
    minWidth?: string;
}
/**
 * 
 * @param param0 
 * @returns 
 */
export default function BigBadgeImage({imgSrc, title, subTitle, onDelete, plusIconRight, onClick, titleRight, validatedIconRight, message, minWidth}: BigBadgeImageProps) {

  const dynamicStyle = {minWidth: minWidth || '250px'}
  return (
    <div style = {dynamicStyle}className={[styles['big-badge-image'], onClick && styles['big-badge-image--clickable']].join(" ")}
      onClick={(e) => {e.preventDefault(); onClick && onClick()}}
    >
        {
          onDelete  && <button className={styles['big-badge-image__delete-btn']} onClick={(e) => {e.preventDefault(); onDelete()}}><img src={iconDelete} /></button>
        }
        <div className= {styles['image-container']}>
            <img className={styles['image-container__image']} src={imgSrc} alt={title} />
        </div>
      
     
        <div className= {styles['text-container']}>
            <h2 className={styles['text-container__title']}>{title} {titleRight &&<span>{titleRight}</span>}</h2>
            <p className={[styles['text-container__sub-title']].join(" ")}>{subTitle}</p>
            <p className={[styles['text-container__message']].join(" ")}>{message}</p>
        </div>
      
      {
        plusIconRight && <img className={styles['big-badge-image__plus-icon-right']} src={plusIcon} />
      }

    
    </div>
  )
}
