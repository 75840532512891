import React, { useEffect } from "react";
import { Project } from "../../../../project/types/project.type";
import { Employee } from "../../../../employee/types/employee.type";
import TeamCreateUpdateFormView from "./TeamCreateUpdateFormView/TeamCreateUpdateFormView";
import {
  CheckTeamInputResult,
  validationInputCreationTeam,
} from "../../../../project/utils/validatorInput";
import {
  Team,
  TEAM_OPTIONS,
  TeamOption,
  TeamPatch,
  TeamRoles,
} from "../../../types/team.types";
import { useTeam } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import teamAPI from "../../../services/teamAPI";
import useUserAuth from "../../../../auth/hook/useUserAuth";
import { chechUserIsAuthorizedTo } from "../../../utils/team.utils";
import { User } from "../../../../user/types/user.types";

export interface CreateTeamFormPrensenterProps {
  idTeamToUpdate?: string;
  initData?: {
    teamName?: string;
    teamDescription?: string;
    project?: Project | null;
    membersList?: User[];
    manager?: User | null;
    role?: TeamRoles | null;
  } | null;

  callbackUpdate?: (result: { result?: any; error?: any }) => void;
}
export default function CreateTeamFormPrensenter({
  idTeamToUpdate,
  initData,
  callbackUpdate,
}: CreateTeamFormPrensenterProps) {


  const [teamName, setTeamName] = React.useState<string>(
    initData?.teamName || ""
  );
  const [teamDescription, setTeamDescription] = React.useState<string>(
    initData?.teamDescription || ""
  );
  const [project, setProject] = React.useState<Project | null>(
    initData?.project || null
  );
  const [membersList, setMembersList] = React.useState<User[]>(
    initData?.membersList || []
  );
  const [manager, setManager] = React.useState<User | null>(
    initData?.manager || null
  );
  const [teamRole, setTeamRole] = React.useState<TeamRoles>(
    initData?.role || "OTHER"
  );

  const [teamOptionsList, setTeamOptionsList] = React.useState<TeamOption[]>(TEAM_OPTIONS);

  const [isLoadingTeamOption, setIsLoadingTeamOption] = React.useState<boolean>(false);

  const [validationInput, setValidationInput] =
    React.useState<CheckTeamInputResult | null>(null);

  const { createNewTeam, updateTeam, deleteTeam } = useTeam();
  const navigate = useNavigate();

  const checkInput = () => {
    const team: TeamPatch = {
      team_name: teamName,
      team_description: teamDescription,
      team_project: project?.project_id || "",
      team_users: membersList.map((employee) => employee.user_id),
      team_manager: teamRole !== "COMPTA_ADMIN" ? manager?.user_id : "",
      team_role: teamRole,
    };

    const checkResult = validationInputCreationTeam(team);
    setValidationInput(checkResult);
    if (checkResult.allFalse) {
      return team;
    }

    return null;
  };

  const callbackCreate = ({
    result,
    error,
  }: {
    result?: Team;
    error?: any;
  }) => {
    if (result && !idTeamToUpdate) {
      navigate("/teams/" + result.team_id, { replace: true });
    }
  };

  const callbackDelete = ({ result, error }: { result?: any; error?: any }) => {
    if (result) {
      navigate("/teams");
    }
  };
  const oncCallbackUpdate = ({
    result,
    error,
  }: {
    result?: any;
    error?: any;
  }) => {
    callbackUpdate && callbackUpdate({ result, error });
  };

  const submitForm = () => {
    const team = checkInput();

    console.log(team);

    if (team) {
      if (!idTeamToUpdate) {
        createNewTeam.run({ newTeam: team, callback: callbackCreate });
        return;
      } else {

        

        updateTeam.run({
          idTeam: idTeamToUpdate,
          teamUpdated: team,
          callback: oncCallbackUpdate,
        });
      }
    }
  };


  useEffect(() => {
    setIsLoadingTeamOption(true);
    teamAPI
      .fecthTeamAPIProtected({
        role: "COMPTA_ADMIN",
      })
      .then((result) => {
        setIsLoadingTeamOption(false);
        console.log(result.data)
        if(result && result.data && result.data.data?.length && result.data.data?.length > 0&&
        idTeamToUpdate !== result.data.data[0].team_id)
        {

          editTeamOptionListRole({
            value: "COMPTA_ADMIN",
            label: "Comptable",
            disable: true,
            message: "Une équipe comptable existe déjà ("+result.data.data[0].team_name+")"
          } , teamOptionsList)
        }
      }).catch(((error) => {
        setIsLoadingTeamOption(false);
      }));
      // eslint-disable-next-line 
  }, []);

  const onDeleteTeam = () => {
    if (idTeamToUpdate) {
      deleteTeam.run({ idTeam: idTeamToUpdate, callback: callbackDelete });
    }
  };

  const editTeamOptionListRole = (
 
    option: TeamOption,
    teamOptionsList: TeamOption[]
  ) => {
    const newList = teamOptionsList.map((optionItem) => {
      if (optionItem.value === option.value) {
        return option;
      }
      return optionItem;
    });

    setTeamOptionsList(newList);
  };

  const {userInfo} = useUserAuth()

  if (!userInfo ||
    chechUserIsAuthorizedTo((idTeamToUpdate ? 'modify' : 'create'), userInfo?.user_role) === false
  ){
    return (<p>Désolé, vous n'êtes pas authorisé à {idTeamToUpdate ? "modifer cette équipe": "créer une équipe" }.</p>)
  }


  return (
    <TeamCreateUpdateFormView
      teamName={teamName}
      setTeamName={setTeamName}
      teamDescription={teamDescription}
      setTeamDescription={setTeamDescription}
      project={project}
      setProject={setProject}
      membersList={membersList}
      setMembersList={setMembersList}
      manager={manager}
      setManager={setManager}
      onSubmitForm={submitForm}
      employeeProhibitedListForManager={membersList?.map((employee) => ({
        id: employee.user_id,
        description: "Membre de cette équipe",
      }))}
      employeeProhibitedListForMembers={
        manager
          ? [
              {
                id: manager.user_id,
                description: "Manager de cette équipe",
              },
            ]
          : []
      }
      isErrorName={validationInput?.isErrorName}
      isErrorDescription={validationInput?.isErrorDescription}
      isErrorProject={validationInput?.isErrorProject}
      isErrorMembers={validationInput?.isErrorMembers}
      isErrorManager={validationInput?.isErrorManager}
      isLoadingSending={createNewTeam.isLoading}
      submitBtnLabel={
        idTeamToUpdate ? "Enregistrer les modifications" : "Créer l'équipe"
      }
      onDeleteTeam={idTeamToUpdate ? onDeleteTeam : undefined}
      teamRole={teamRole}
      setTeamRole={setTeamRole}
      teamRoleOptions={teamOptionsList}
      isLoadingTeamOption={isLoadingTeamOption}
    />
  );
}
