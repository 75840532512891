import React from "react";
import BigBadgeImage from "../../../../../components/molecules/BigBadgeImage/BigBadgeImage";
import styles from "./memberListView.module.scss";
import avatar from "./../../../assets/avatar-img.svg";
import avatarIcon from "./assets/avatar-icon.svg";
import Loader from "../../../../../components/atoms/Loader/Loader";

export interface MemberListViewItem {
  imgSrc?: string;
  firstName: string;
  lastName: string;
  email?: string;
  onClick?: () => void;
  onDelete?: () => void;
  plusIconRight?: boolean;
}

export interface MemberListViewProps {
  members: MemberListViewItem[];
  flexDirection?: "row" | "column";
  isloading?: boolean;
  emptyMessage?: string;
  maxHeight?: string;
  ordered?: boolean;
}

export default function MemberListView({ members, flexDirection = 'column', isloading, emptyMessage= 'Aucun employé', maxHeight, ordered}: MemberListViewProps) {

  const stylesDynamic ={
    maxHeight: maxHeight || 'none',
    overflow : 'auto'
  }


  const oderedMembers = ordered ? members.sort((a, b) => {
    if (a.firstName < b.firstName) {
      return -1;
    }
    if (a.firstName > b.firstName) {
      return 1;
    }
    return 0;
  }) : null;

  if (isloading) {
    return <div className={styles["loader-container"]}><Loader/></div>;
  }
  if (!members || members.length === 0) {
    return (<div className={styles["empty-list-container"]} >
      <img className={styles["empty-list-container__icon"]} src={avatarIcon} alt="empty-list" />
      <p className={styles["empty-list-container__message"]}>{emptyMessage}</p>
    </div>);
  }
  return (
    <div className={styles['container']}>
          {maxHeight &&
          <div className={[styles["fade-effect"], styles["fade-effect--top"]].join(" ")}/>
}
    <li style={stylesDynamic} className={[styles["members-list"], styles["members-list--"+flexDirection]].join(" ")}>
      {(oderedMembers || members).map((member, index) => (
        <ul className={[styles["members-list__items"], styles["members-list__items--"+flexDirection]].join(" ")} key={index}>
          <BigBadgeImage
            title={member.firstName}
            subTitle={member.lastName}
            message={member.email}
            imgSrc={avatar}
            onDelete={member.onDelete}
            onClick={member.onClick}
            plusIconRight={member.plusIconRight}
            minWidth="280px"
          />
        </ul>
      ))}
      {maxHeight &&
      <div className={styles["gap"]}/>
      }
    </li>
    {maxHeight &&
    <div className={[styles["fade-effect"], styles["fade-effect--bottom"]].join(" ")}/>
    }
    </div>

  );
}
