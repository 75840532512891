import React from 'react'
import BigBadgeImage from '../../../../../../components/molecules/BigBadgeImage/BigBadgeImage'
import './managerInfoCardView.scss'
import avatar from './../../../../assets/avatar-img.svg'
export interface ManagerCardProps {

    firstName?: string;
    lastName?: string;
    imageUrl?: string;
    email?: string;
}
function ManagerInfoCardView({firstName, lastName, email}: ManagerCardProps) {
  return (
    <div className='manager-card'> 
    <BigBadgeImage title={firstName} subTitle={lastName} imgSrc={avatar} message={email}/>
    </div>
  )
}

export default ManagerInfoCardView