import React from "react";
import styles from "./SearchEmployeeMemberModalView.module.scss";
import SearchPickEmployee from "../../SearchPickUser";
import MemberList from "../../../../team/components/SingleTeam/MembersList";
import MemberListView, {
  MemberListViewItem,
} from "../../../../team/components/common/MembersListView/MemberListView";
import ArrowBack from "./assets/arrow-back.svg";
import Button from "../../../../../components/molecules/Button/Button";

import SearchPickUserPresenter, { ProhibitedItem } from "../../SearchPickUser/SearchSelectUserPresenter";
import { Employee } from "../../../../employee/types/employee.type";
import { User } from "../../../types/user.types";


export interface SearchUserMemberModalViewProps {
  onClose?: () => void;
  membersPickedList?: MemberListViewItem[];
  labelCloseBtn?: string;
  title?: string;
  addEmployee?: (employee: User) => void;
  onSave?: () => void;
  noList?: boolean;
  employeeProhibitedList?: ProhibitedItem[];
  emptySelectedMessage? : string
  titleListSelected?: {single: string, plural: string}

}

export default function SearchUserMemberModalView({
  onClose,
  membersPickedList,
  labelCloseBtn = "Retourner",
  title = "Modifier la liste des membres",
  addEmployee,
  onSave,
  noList,
  employeeProhibitedList, 
  emptySelectedMessage="Aucun utilisateur",
  titleListSelected={single:"Membre", plural:"Membres"}
}: SearchUserMemberModalViewProps) {



 
  

  return (
    <div className={styles["modal-search"]}>
      {onClose && (
        <button
          className={styles["close-btn"]}
          onClick={(e) => {
            onClose();
          }}
        >
          <img
            className={styles["close-btn__icon"]}
            src={ArrowBack}
            alt="Retourner"
          />
          <span className={styles["close-btn__label"]}>{labelCloseBtn}</span>
        </button>
      )}
      <h1 className={styles["modal-search__title"]}>{title}</h1>
      <div className={styles["content-main"]}>
        {!noList && <>
        <div
          className={[
            styles["content-side"],
            styles["content-side--member"],
          ].join(" ")}
        >
          <h2 className={styles["content-side__title"]}>
            { membersPickedList && membersPickedList?.length > 1 ? titleListSelected.plural: titleListSelected.single } ({membersPickedList?.length || 0})
          </h2>
          <div className={styles["menber-list"]}>
            <MemberListView
              members={ membersPickedList || []}
              emptyMessage={emptySelectedMessage}
              maxHeight={'800px'}
              ordered
            />
          </div>
        </div>


        <div className={styles["separator-container"]}>
          <div className={styles["separator-container__separator"]} />
        </div>
        </>
}
        <div
          className={[
            styles["content-side"],
            styles["content-side--search-employee"],
          ].join(" ")}
        >
          <p className={[styles["content-side__sub-title"]].join(" ")}>
            Rechercher un(e) employé(e) {!noList && 'à ajouter à la liste'}
          </p>
          <SearchPickUserPresenter
            onClickEmployeeFound={(employee) => {
              if (addEmployee) addEmployee(employee);
            }}
            employeeProhibitedList={employeeProhibitedList}
          />
        </div>
      </div>

      <div className={styles["btn-save"]}>
        {onSave &&
          <Button label="Sauvegarder" style="primary" maxWidth="150px" onClick={() => {onSave()}} iconArrow/>
        }
      </div>
    </div>
  );
}
