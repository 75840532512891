import React, { useState } from "react";
import Button from "../../../../../../components/molecules/Button/Button";
import SearchEmployeeMemberModal from "../../../../../employee/components/SearchEmployeeMemberModal";
import { Employee } from "../../../../../employee/types/employee.type";
import { employeeProhibitedItem } from "../../../../../employee/components/SearchPickEmployee/SearchSelectEmployeePresenter";
import SearchUserMemberModal from "../../../../../user/components/SearchUserMemberModal";
import { User } from "../../../../../user/types/user.types";
import { ProhibitedItem } from "../../../../../user/components/SearchPickUser/SearchSelectUserPresenter";


export interface AddSearchNewMemberBtnPresenterProps {
  currentMembersList: User[];
  setNewMemberList: (employee: User[]) => void;
  label?: string;
  memberProhibitedList?: User[];
  employeeProhibitedList?: ProhibitedItem[];
}

export default function AddSearchNewMemberBtnPresenter({currentMembersList, setNewMemberList, employeeProhibitedList,label = "Ajouter un membre" }: AddSearchNewMemberBtnPresenterProps) {

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen &&
        <SearchUserMemberModal employeeProhibitedList={employeeProhibitedList} onClose={handleModalClose} currentMembersList={currentMembersList} setNewMemberList={setNewMemberList} />
      }
      <Button onClick={() => {handleModalOpen()}} maxWidth="180px" label={label} style="black" />
    </>
  );
}
