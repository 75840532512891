import { time } from "console";
import { Team, TeamPatch } from "../../team/types/team.types";
import { checkValidity } from "../../../utils/inputValidityChecker";
import { isError } from "util";
import { all } from "axios";

export  interface CheckTeamInputResult {
    isErrorName: boolean;
    isErrorDescription: boolean;
    isErrorProject: boolean;
    isErrorMembers: boolean;
    isErrorManager: boolean;
    allFalse: boolean;



}
export const validationInputCreationTeam = (team:  TeamPatch) => {
    const initCheck = {
        isErrorName: false,
        isErrorDescription: false,
        isErrorProject: false,
        isErrorMembers: false,
        isErrorManager: false,
        allFalse: true
    }

    let errors = initCheck;


    if (!checkValidity(team.team_name, 'text-not-empty')){
        errors.isErrorName = true;
        errors.allFalse = false;
    }
    if(team.team_role !== "COMPTA_ADMIN" && !checkValidity(team.team_manager, 'text-not-empty') ){
        errors.isErrorManager = true;
        errors.allFalse = false;
    }

    if(team.team_users?.length === 0){
        errors.isErrorMembers = true;
        errors.allFalse = false;
    }

    return errors;

}