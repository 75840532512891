import React, { useEffect, useState } from "react";
import TeamFilterView from "./TeamFilterView/TeamFilterView";
import { Employee } from "../../../../employee/types/employee.type";
import { useTeam } from "../../../hooks";
import { get } from "http";
import employeeAPI from "../../../../employee/services/employeeAPI";
import { Project } from "../../../../project/types/project.type";
import projectAPI from "../../../../project/services/projectAPI";
import { User } from "../../../../user/types/user.types";
import userAPI from "../../../../user/services/user.api";

export type EmployeeOpt = Partial<Employee>
;

export default function TeamFilterPresenter() {
  const [listManager, setListManagerFunc] = useState<User[]>([]);
  const [listMember, setListMemberFunc] = useState<User[]>([]);
  const [isLoadingManager, setIsLoadingManager] = useState(false);
  const [isLoadingMember, setIsLoadingMember] = useState(false);
  const [ isResetPage, setResetPage ] = useState(false);
  const [listProject, setListProjecFunc] = useState<Project[]>([]);
  const [isLoadingProject, setIsLoadingProject] = useState(false);

  const { setFilterListTeam, getFilterListTeam } = useTeam();
  const updateFilter = (currentPage?: number) => {

    setFilterListTeam({
      managerEmployeeId: listManager.reduce(
        (acc, manager, index) =>
          acc + (index > 0 ? "," : "") + manager.user_id,
        ""
      ),
      memberEmployeeId: listMember.reduce(
        (acc, manager, index) =>
          acc + (index > 0 ? "," : "") + manager.user_id,
        ""
      ),
      projectId: listProject.reduce(
        (acc, project, index) =>
          acc + (index > 0 ? "," : "") + project.project_id,
        ""
      ),
    }, currentPage );
  }

  useEffect(() => {
    setResetPage(false)
  }, [])

  useEffect(() => {
    if (isResetPage){
      updateFilter(1);
    }
  }, [listManager, listMember,  listProject]);




  const setListManager = (manager: User[]) => {
    setResetPage(true);
    setListManagerFunc(manager);    
  }

  const setListMember = (member: User[]) => {
    setResetPage(true);
    setListMemberFunc(member);


  }
  const setProject = (project: Project[]) => {
    setResetPage(true);
    setListProjecFunc(project);
  }
  

  const getEmployeeManager = async () => {
    if (getFilterListTeam) {
      const filternew = getFilterListTeam();
      setIsLoadingManager(true)
      if (filternew) {
        try{
        const listManagerFormat : User[] =  await Promise.all(
          filternew.managerEmployeeId
            ? filternew.managerEmployeeId
                .split(",")
                .map(async (employee) => (await userAPI.getSingleUserProtectedAPI(employee)).data)
            : []
        );

        setListManagerFunc(listManagerFormat);
      }catch(e){
        setListMemberFunc([]);
        setIsLoadingManager(false)
      }
      }
    }
    setIsLoadingManager(false)
  }

  const getEmployeeMember = async () => {
    if (getFilterListTeam) {
      const filternew = getFilterListTeam();
      setIsLoadingMember(true)
      if (filternew) {
        try{

        
        const listManagerFormat : User[] =  await Promise.all(
          filternew.memberEmployeeId
            ? filternew.memberEmployeeId
                .split(",")
                .map(async (employee) => (await userAPI.getSingleUserProtectedAPI(employee)).data)
            : []
        );

        setListMemberFunc(listManagerFormat);
      }catch(e){
        setListMemberFunc([]);
   
      }
      }
    }
    setIsLoadingMember(false)
  }

  const getProject = async () => {
    if (getFilterListTeam) {
      const filternew = getFilterListTeam();
      setIsLoadingMember(true)
      if (filternew) {
        try{
        const listProjectFormat : Project[] =  await Promise.all(
          filternew.projectId
            ? filternew.projectId
                .split(",")
                .map(async (projectId) => await projectAPI.getSingleProjectProtectedAPI(projectId))
            : []
        );

        setListProjecFunc( listProjectFormat);
      }catch(e){
        setListProjecFunc([]);
   
      }
      }
    }
    setIsLoadingMember(false)
  }
  useEffect(() => {
    getEmployeeManager();
    getEmployeeMember();
    getProject();
  }, []);


  return (
    <TeamFilterView
      setManagerList={setListManager}
      currentManagerList={listManager}
      setMemberList={setListMember}
      currentMemberList={listMember}
      isLoadingManagerFilter={isLoadingManager}
      isLoadingMemberFilter={isLoadingMember}
      setProjectList={setProject}
      currentProjectList={listProject}
    />
  );
}
