import React, { useState } from "react";

import SearchEmployeeMemberModalView from "./SearchUserMemberModalView/SearchUserMemberModalView";

import { MemberListViewItem } from "../../../team/components/common/MembersListView/MemberListView";
import {  ProhibitedItem } from "../SearchPickUser/SearchSelectUserPresenter";

import SearchUserMemberModalView from "./SearchUserMemberModalView/SearchUserMemberModalView";
import { User } from "../../types/user.types";

export interface SearchEmployeeMemberModalPresenterProps {
  onClose?: () => void;
  currentMembersList?: User[] | null;
  addMemberToTeam?: (employee: User) => void;
  setNewMemberList?: (employee: User[]) => void;
  setEmployeeOne?: (employee: User) => void;
  noList?: boolean;
  titleModal?: string;
  employeeProhibitedList?:ProhibitedItem[];
  titleListSelected?: {single: string, plural: string}
}


export default function SearchUserMemberModalPresenter({
  onClose,
  currentMembersList,
  setNewMemberList,
  setEmployeeOne,
  noList,
  titleModal,
  employeeProhibitedList,
  titleListSelected
}: SearchEmployeeMemberModalPresenterProps) {


  const [newMemberListTmp, setNewMenberList] = useState<User[]>(currentMembersList || [])

  employeeProhibitedList = employeeProhibitedList || [];

  const employeeAlreadyInList : ProhibitedItem[] = newMemberListTmp.map((member) => ({id: member.user_id, description: 'Déjà dans la liste'}));

  const employeeProhibitedListTmp = [...employeeProhibitedList, ...employeeAlreadyInList];
  const convertEmployeeToMemberListViewItem = (employee: User): MemberListViewItem => {
    return {
      firstName: employee.username,
      lastName: employee.lastname,
      email: employee.email,
      
      onDelete: () => {
        setNewMenberList(newMemberListTmp.filter((member) => member.user_id !== employee.user_id));
      },
    };
  }
  
  const convertListEmployeeToListMemberListViewItem = (employees: User[]): MemberListViewItem[] => {
    return employees.map((employee) => convertEmployeeToMemberListViewItem(employee));
  }

  const handleAddEmployee = (employee: User) => {
    if(setEmployeeOne){
      setEmployeeOne(employee);
      onClose && onClose();
      return;
    }
 
    if(newMemberListTmp.find((member) => member.user_id === employee.user_id)) {
      return;
    }
    setNewMenberList([...newMemberListTmp, employee]);

  };

  const onSave = () => {
    if (setNewMemberList){
      setNewMemberList(newMemberListTmp);
      onClose && onClose();
    }

  };




  return (
    <SearchUserMemberModalView
      onClose={onClose}
      addEmployee={handleAddEmployee}
      membersPickedList={convertListEmployeeToListMemberListViewItem(newMemberListTmp)}
      employeeProhibitedList={employeeProhibitedListTmp}
      onSave={setNewMemberList ? onSave : undefined}
      noList={noList}
      title={titleModal}
      titleListSelected={titleListSelected}
    />
  );
}
