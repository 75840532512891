import React, { useState } from "react";
import SearchEmployeeMemberModal, { SearchUserMemberModal } from "../SearchUserMemberModal";
import SelectButton from "../../../../components/atoms/SelectButton/SelectButton";

import { User } from "../../types/user.types";

export interface SelectUserListBtnProps {
  titleModal?: string;
  setManagerList?: (manager: User[]) => void;
  currentUserList?: User[] | null | undefined;
  titleListSelected?: { single: string; plural: string };
  label?: string;
  isLoading?: boolean;
  placeholder?: string;
  withDeleteAllBtn?: boolean
}
export default function SelectUserBtn({
  setManagerList,
  titleModal = "Ajouter des managers",
  currentUserList,
  label,
  isLoading,
  withDeleteAllBtn,

  titleListSelected = {
    single: "Utilisateur Sélectionné",
    plural: "Utilisateur Sélectionnés",
  },
  placeholder= "Tous",
}: SelectUserListBtnProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {isModalOpen && (
        <SearchUserMemberModal
        
          titleListSelected={titleListSelected}
          titleModal={titleModal}
          onClose={handleModalClose}
          setNewMemberList={setManagerList}
          currentMembersList={currentUserList}
          employeeProhibitedList={
            currentUserList?.map((employee) => ({
              id: employee.user_id,
              description: "Déjà dans la liste",
            })) || []
          }
        />
      )}
      <SelectButton
            deleteAllItems={ withDeleteAllBtn ? () => {   setManagerList &&  setManagerList([])} : undefined}
        label={label}
        getNameItem={(item: User) => `${item.username} ${item.lastname}`}
        valueList={currentUserList}
        onClick={handleModalOpen}
        isLoading={isLoading}
        placeholder={placeholder} 
      />
    </>
  );
}
