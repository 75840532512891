import React from 'react'
import ManagerInfoCardView from './ManagerInfoCardView/ManagerInfoCardView'
import { useSingleTeam } from '../../../hooks'

/**
 * Props for the ManagerInfoCardPresenter component.
 */


export interface ManagerInfoCardPresenterProps {
  TitleComponent?: React.FC
}
function ManagerInfoCardPresenter({TitleComponent}: ManagerInfoCardPresenterProps) {
  const {isLoading, singleTeamData} = useSingleTeam()

  if (isLoading || !singleTeamData) return <></>

  if (!singleTeamData.team_manager) return <></>

  return (

    <>
    {TitleComponent && <TitleComponent/>}
   
    <ManagerInfoCardView 
    lastName={singleTeamData?.team_manager?.lastname }
    firstName={singleTeamData?.team_manager?.username}
    email={singleTeamData?.team_manager?.email}
  
    />
    </>
  )
}

export default ManagerInfoCardPresenter