import React from "react";
import styles from "./SearchPickEmployeeView.module.scss";
import TextInput from "../../../../../components/atoms/TextInput/TextInput";
import MemberListView, {
  MemberListViewItem,
} from "../../../../team/components/common/MembersListView/MemberListView";
import Pagination from "../../../../../components/organismes/Pagination/Pagination";


export interface SearchPickEmployeeViewProps {
  isLoading?: boolean;
  employeeFound?: MemberListViewItem[];
  searchValue: string;
  setSearchValue: (value: string) => void;
  nbEmployeeFound?: number;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  lastPage?: number;
  
}
export default function SearchPickEmployeeView({
  employeeFound,
  isLoading,
  searchValue,
  setSearchValue,
  nbEmployeeFound,
  currentPage,
  lastPage,
  setCurrentPage


}: SearchPickEmployeeViewProps) {

  const displayNbFound = ((employeeFound && (nbEmployeeFound)) && (nbEmployeeFound > 0)) ? true : false;
  return (
    <div className={styles["search-pick-employee"]}>
      <div className={styles["search-pick-employee__search-bar"]}>
        <TextInput
          placeholder="Nom, Prénom"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      </div>
      <div className={styles["search-pick-employee__result-count"]}>
        {  (displayNbFound  && nbEmployeeFound) &&
          <span>{nbEmployeeFound} { nbEmployeeFound > 1 ?'utilisateur trouvés' : 'utilisateur trouvé'}</span>
        }
        </div>
      <div className={styles["search-pick-employee__result"]}>
        <MemberListView
          members={employeeFound || []}
          flexDirection="row"
          emptyMessage="Aucun utilisateur trouvé"
          isloading={isLoading}
        />
              <Pagination currentPage={currentPage} setCurrentPage={  setCurrentPage} lastPage={lastPage } nbBtnToDisplay={5} centered/>
      </div>

    </div>
  );
}
