import React from 'react'
import styles from './TeamFilterView.module.scss'
import SelectButton from '../../../../../../components/atoms/SelectButton/SelectButton'
import SelectEmployeeBtn from '../../../../../employee/components/SelectEmployeeBtn/SelectEmployeeBtn'
import { Employee } from '../../../../../employee/types/employee.type'
import { Project } from '../../../../../project/types/project.type'
import SelectProjectBtn from '../../../../../project/components/SelectProjectBtn/SelectProjectBn'
import { User } from '../../../../../user/types/user.types'
import SelectUserBtn from '../../../../../user/components/SelectUserBtn/SelectUserBtn'

export interface TeamFilterViewProps {

  currentManagerList?: User[],
  setManagerList?: (manager: User[]) => void;
  currentMemberList?: User[],
  setMemberList?: (manager: User[]) => void;
  isLoadingManagerFilter?: boolean;
  isLoadingMemberFilter?: boolean;
  setProjectList?: (project: Project[]) => void;
  currentProjectList?: Project[],
  isLoadingProjectFilter?: boolean;

}
export default function TeamFilterView({
  currentManagerList,
  setManagerList,
  currentMemberList,
  setMemberList,
  isLoadingManagerFilter,
  isLoadingMemberFilter,
  setProjectList,
  currentProjectList,
  isLoadingProjectFilter,
}: TeamFilterViewProps) {
  return (
    <div className={styles['filter']}>
      <div className={styles['filter__item']}>
       <SelectUserBtn
        currentUserList={currentManagerList}
        setManagerList={setManagerList}
        titleModal='Ajouter des managers au filtre'
        label='Manager'
        titleListSelected={{single: 'Manager Sélectionné', plural: 'Managers Sélectionnés'}}
        isLoading={isLoadingManagerFilter}
        placeholder='Aucun'
        withDeleteAllBtn
       />
      </div>
       <div className={styles['filter__item']}>
        <SelectUserBtn
        currentUserList={ currentMemberList}
        setManagerList={setMemberList}
        titleModal='Ajouter des membre au filtre'
        label='Membre'
        titleListSelected={{single: 'Membre Sélectionné', plural: 'Membres Sélectionnés'}}
        isLoading={isLoadingMemberFilter}
        placeholder='Aucun'
        withDeleteAllBtn
       />
         </div>
         <div className={styles['filter__item']}>
        <SelectProjectBtn
        currentProjectList={ currentProjectList}
        setProjectList={setProjectList}
        titleModal='Ajouter un projet'
        label='Projet'
        titleListSelected={{single: 'Projet Sélectionné', plural: 'Projets Sélectionnés'}}
        isLoading={isLoadingProjectFilter}
        placeholder='Aucun'
        withDeleteAllBtn
       />
         </div>
    </div>
  )
}
