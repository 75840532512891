import React from 'react'
import MemberListView from '../../common/MembersListView/MemberListView'
import { useSingleTeam } from '../../../hooks'

export interface MemberListPresenterProps {
  TitleComponent?:React.FC<{nbMember?: number | undefined}>
}
export default function MemberListPresenter({TitleComponent}: MemberListPresenterProps) {

    
    
    const {memberList:{membersListData, isLoading}} = useSingleTeam()


    
    const membersListFormated = membersListData?.data?.map(employee => ({
        firstName : employee.username,
        lastName : employee.lastname,
        email: employee.email ,
    
    }))
  return (
    <>
    {TitleComponent && <TitleComponent nbMember={membersListData?.data?.length}/>}

    <MemberListView members={membersListFormated || []}   maxHeight='500px'  ordered/>
    </>
  )
}
